@font-face {
    font-family: 'fontello';
    src: url('../fonts/fontello.eot?68165650');
    src: url('../fonts/fontello.eot?68165650#iefix') format('embedded-opentype'),
    url('../fonts/fontello.woff2?68165650') format('woff2'),
    url('../fonts/fontello.woff?68165650') format('woff'),
    url('../fonts/fontello.ttf?68165650') format('truetype'),
    url('../fonts/fontello.svg?68165650#fontello') format('svg');
    font-weight: normal;
    font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../fonts/fontello.svg?68165650#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: never;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-eventi:before { content: '\e800'; } /* '' */
.icon-no_profit:before { content: '\e801'; } /* '' */
.icon-chef_box:before { content: '\e802'; } /* '' */
.icon-promozioni-01:before { content: '\e803'; } /* '' */
.icon-sconto:before { content: '\e804'; } /* '' */
.icon-omaggio-1:before { content: '\e805'; } /* '' */
.icon-add1:before { content: '\e806'; } /* '' */
.icon-add2:before { content: '\e807'; } /* '' */
.icon-musician:before { content: '\e808'; } /* '' */
.icon-search-1:before { content: '\e809'; } /* '' */
.icon-tg-search-on:before { content: '\e80a'; } /* '' */
.icon-tg-search-off:before { content: '\e80b'; } /* '' */
.icon-service-delivery-1:before { content: '\e80c'; } /* '' */
.icon-spedito:before { content: '\e80d'; } /* '' */
.icon-servito:before { content: '\e80e'; } /* '' */
.icon-domicilio:before { content: '\e80f'; } /* '' */
.icon-date-today:before { content: '\e810'; } /* '' */
.icon-photographer:before { content: '\e811'; } /* '' */
.icon-cook:before { content: '\e812'; } /* '' */
.icon-barman:before { content: '\e813'; } /* '' */
.icon-waiter:before { content: '\e814'; } /* '' */
.icon-assistant:before { content: '\e815'; } /* '' */
.icon-public-relation:before { content: '\e816'; } /* '' */
.icon-food_guru:before { content: '\e817'; } /* '' */
.icon-logo-trovacigusto:before { content: '\e818'; } /* '' */
.icon-logo-squared:before { content: '\e819'; } /* '' */
.icon-dinner:before { content: '\e81a'; } /* '' */
.icon-date-other:before { content: '\e81b'; } /* '' */
.icon-lunch:before { content: '\e81c'; } /* '' */
.icon-ricetta5:before { content: '\e81d'; } /* '' */
.icon-service-takeaway:before { content: '\e81e'; } /* '' */
.icon-service-table:before { content: '\e81f'; } /* '' */
.icon-search:before { content: '\e820'; } /* '' */
.icon-service-delivery:before { content: '\e821'; } /* '' */
.icon-fan:before { content: '\e822'; } /* '' */
.icon-menuspeciali:before {
    content: '\e823';
}

/* '' */
.icon-food_kit:before {
    content: '\e824';
}

/* '' */
.icon-news:before {
    content: '\e825';
}

/* '' */
.icon-delivery:before {
    content: '\e826';
}

/* '' */
.icon-manager:before {
    content: '\e827';
}

/* '' */
.icon-babysitter:before {
    content: '\e828';
}

/* '' */
.icon-sommelier:before {
    content: '\e829';
}

/* '' */
.icon-artist:before {
    content: '\e82a';
}

/* '' */
.icon-offer-type-service:before {
    content: '\e82b';
}

/* '' */
.icon-food-network:before {
    content: '\e82c';
}

/* '' */
.icon-coupon-o:before {
    content: '\e82d';
}

/* '' */
.icon-coupon:before {
    content: '\e82e';
}

/* '' */
